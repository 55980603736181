/*--------------------------------------------------------------------- File Name: style.css ---------------------------------------------------------------------*/


/*--------------------------------------------------------------------- import Files ---------------------------------------------------------------------*/

/*@import url(animate.min.css);*/
/*@import url(normalize.css);*/
/*@import url(icomoon.css);*/
/*@import url(css/font-awesome.min.css);*/
/*@import url(meanmenu.css);*/
/*@import url(owl.carousel.min.css);*/
/*@import url(swiper.min.css);*/
/*@import url(slick.css);*/
/*@import url(jquery.fancybox.min.css);*/
/*@import url(jquery-ui.css);*/
/*@import url(nice-select.css);*/

/*--------------------------------------------------------------------- skeleton ---------------------------------------------------------------------*/

* {
    box-sizing: border-box !important;
    transition: ease all 0.5s;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #666666;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    line-height: 1.80857;
    font-weight: normal;
    max-width: 100%;
    overflow-x: hidden;
}

a {
    color: #1f1f1f;
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 0;
    font-weight: normal;
    position: relative;
    padding: 0 0 10px 0;
    font-weight: normal;
    line-height: normal;
    color: #111111;
    margin: 0
}

h1 {
    font-size: 24px
}

h2 {
    font-size: 22px
}

h3 {
    font-size: 18px
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 13px
}

*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #212121;
    text-decoration: none!important;
    opacity: 1
}

button:focus {
    outline: none;
}

ul,
li,
ol {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

p {
    margin: 20px;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
}

a {
    color: #222222;
    text-decoration: none;
    outline: none !important;
}

a,
.btn {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.svg-in-array svg {
    color: #136270;
    height: 1.7rem;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

 :focus {
    outline: 0;
}

.paddind_bottom_0 {
    padding-bottom: 0 !important;
}

.btn-custom {
    margin-top: 20px;
    background-color: transparent !important;
    border: 2px solid #ddd;
    padding: 12px 40px;
    font-size: 16px;
}

.lead {
    font-size: 18px;
    line-height: 30px;
    color: #767676;
    margin: 0;
    padding: 0;
}

.form-control:focus {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

.navbar-form input {
    border: none !important;
}

.badge {
    font-weight: 500;
}

blockquote {
    margin: 20px 0 20px;
    padding: 30px;
}

button {
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.full {
    float: left;
    width: 100%;
}

.layout_padding {
    padding-top: 100px;
    padding-bottom: 0px;
}

.padding_0 {
    padding: 0px;
}

.display-linebreak {
    white-space: pre-line;
}


/* header section start */

.header_section {
    width: 100%;
    float: left;
    background-color: #0e0c06;
    height: auto;
    background-size: 100%;
}

.header_bg {
    width: 100%;
    float: left;
    background: #1d1d1d;
    height: auto;
    background-size: 100%;
}

.bg-light {
    background-color: transparent !important;
}

.mr-auto,
.mx-auto {
    margin: 0 auto;
    text-align: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px 22px;
    font-size: min(1.7vw, 24px);
    max-height: 40px;
    color: #fefefd;
    font-weight: 300;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #f7d700;
}


.navbar-brand {
    margin: 0px;
    margin-right: 5%;
    float: right;
    color: white;
    font-family: Gagalin;
    /* font-size: min(35px,max(1.6vw, 20px)); */
    font-size: 42px;
}

.navbar-brand:focus,
.navbar-brand:hover {
    color: #f7d700;
}

.contactButton{
    color: white;
    border: 1px solid white;
    border-radius: 8px;
}

.contactButton:focus,
.contactButton:hover {
    color: #f7d700;
    border: 1px solid #f7d700;

}

@media only screen and (max-width: 990px) {
    /* For mobile phones: */
    .navbar-brand {
        margin: 0px;
        font-size: 35px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 0px 22px;
        font-size: 20px;
        text-align: right;
        color: #fefefd;
    }

    .contactButton{
        border: 0;
    }
}

@media only screen and (max-width: 450px) {
    /* For mobile phones: */
    .navbar-brand {
        margin: 0px;
        font-size: 25px;
    }
}





.call_text_main{
    width: 100%;
    float: left;
    background-color: #fe5b29;
    height: auto;
    padding: 10px 0px;
}

.call_taital{
    width: 100%;
    display: flex;
}

.call_text {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.call_text a {
    width: 100%;
    float: left;
    color: #ffffff;
    font-size: 16px;
    padding: 0px 20px;
}

.call_text a:hover {
    color: #363636;
}

.padding_left_15 {
    padding-left: 10px;
}

/* header section end */

#homepage, #depot-vente-section {
    margin-top: 125px;
}

@media only screen and (max-width: 990px) {
    /* For mobile phones: */
    #homepage, #depot-vente-section {
        margin-top: 95px;
    }
}


/* banner section start */
/* banner section end */

/* about section start */

.banner_img {
    height: 40vw;
    background: linear-gradient(rgba(19, 98, 112, 1), rgba(19, 98, 112, 0.15)), url("../assets/images/banner_min.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
}

.depot-vente_banner_img {
    height: 40vw;
    background: linear-gradient(rgba(19, 98, 112, 1), rgba(19, 98, 112, 0.15)), url("../assets/images/DV_banner_min.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
}

.about-container {
    margin-bottom: -12vw;
}

.about-panel {
    position: relative;
    top: -12vw;
    background-color: white;
    border-radius: 1.5rem;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.288) 0px 4px 28px 0px;
}

.about-title {
    color: #2b3036;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 2.5rem;
}

.depot-vente-title {
    text-align: center;
}

.about-text {
    font-size: 1.5rem;
    line-height: 2rem;
}

.depot-vente-bulletpoint {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 1.5rem;
}

.depot-vente-bulletpoint svg {
    align-self: center;
    color: #f7d700;
    font-size: 2.2rem;
}

.depot-vente-bulletpoint p {
    font-size: 1.3rem;
    color: #2b3036;
    font-weight: 400;
}

.about-contact-button-container {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1400px) {
    /* For mobile phones: */
    .about-title {
        font-size: 1.7rem;
        line-height: 2.5rem;
    }
    
    .about-text {
        line-height: 2rem;
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 990px) {
    /* For mobile phones: */
    .about-panel {
        padding: 1rem;
    }

    .about_img {
        display: none;
    }

    .about-title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .about-text {
        line-height: 2rem;
        font-size: 1.25rem;
        line-height: 1.7rem;
    }
}




/* about section end */

/* search section start */
/* select box section start */

.search_section {
    width: 100%;
    float: left;
    background-color: #363636;
    height: auto;
    padding: 40px 0px;
    background-size: cover;
}

.select_box_section {
    width: 100%;
    float: left;
}

.search_taital{
    width: 100%;
    float: left;
    font-size: 40px;
    color: #fefefd;
    font-weight: bold;
}
.search_text{
    width: 100%;
    float: left;
    font-size: 16px;
    color: #fefefd;
    margin: 0px;
}

.select_box_main {
    width: 100%;
    height: auto;
    padding: 40px 0px 0px 0px;
}

.md-outline.select-wrapper+label {
    top: .5em !important;
    z-index: 2 !important;
}

.nice-select {
    width: 100%;
    background-color: #ffffff !important;
    border: 0px;
    border-radius: 3px;
}

span.current {
    color: #1a1a1a;
}

.nice-select:after {
    width: 10px;
    height: 10px;
}

.nice-select {
    color: #363636;
    font-size: 18px;
}

.search_btn {
    width: 170px;
    float: left;
}

.search_btn a {
    width: 100%;
    float: left;
    font-size: 18px;
    color: #fefefd;
    background-color: #fe5b29;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 3px;
}

.search_btn a:hover {
    color: #363636;
    background-color: #ffffff;
}


/* select box section end */
/* search section end */


/* gallery section start */

.gallery_section {
    width: 100%;
    float: left;
    padding: 90px 0px;
}

.gallery_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #3b3b3b;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0px;
}

.gallery_section_2 {
    width: 100%;
    float: left;
    padding-top: 30px;
}

.gallery-img {
    position: relative;
}

.gallery_img img {
    width: 100%;
    float: left;
    min-height: 182px;
}

.banner-container {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	top: -5px;
	left: -5px;
	&:after {
		content: "";
		display: block;
		width: 10px;
		height: 5px;
		background-color: darken(#f7d700, 5%);
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
	}
	&:before {
		content: "";
		display: block;
		height: 10px;
		width: 5px;
		background-color: darken(#f7d700, 5%);
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
}

.banner-container .banner {
	width: 200px;
	padding: 15px;
	background-color: #f7d700;
	color: #fff;
	text-align: center;
	transform: rotate(-45deg) translate(-28%, -35%);
	box-shadow: 0px 5px 5px rgba(0,0,0,0.4);
	text-transform: uppercase;
	text-shadow: 0 2px 2px rgba(0,0,0,0.4);
}

@media only screen and (max-width: 1200px) {
    /* For mobile phones: */
    .banner-container .banner {
        padding: 12px;
    }
}
@media only screen and (max-width: 990px) {
    /* For mobile phones: */
    .banner-container .banner {
        padding: 8px;
    }
}


.gallery_box {
    width: 100%;
    float: left;
    background-color: #ffffff;
    height: auto;
    padding: 20px 20px;
    box-shadow: 0px 0px 6px 6px #f4f3f2;
}
.gallery_box:hover {
    box-shadow: 0px 0px 0px 0px;
}

.types_text {
    width: 100%;
    float: left;
    font-size: 22px;
    color: #2f2e2d;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 30px;
}

.looking_text {
    width: 100%;
    float: left;
    font-size: 18px;
    color: #fe5b29;
    text-align: center;
    margin: 0px;
    font-weight: bold;
}

.read_bt {
    width: 150px;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    display: flex;
}

.read_bt a {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #fefefd;
    text-align: center;
    background-color: #fe5b29;
    font-weight: bold;
    padding: 10px;
}

.read_bt a:hover {
    color: #fefefd;
    background-color: #363636;
}

#main_slider a.carousel-control-prev {
    left: -50px;
    top: 170px;
}

#main_slider a.carousel-control-next {
    right: -50px;
    top: 170px;

}

#main_slider .carousel-control-next,
#main_slider .carousel-control-prev {
    width: 55px;
    height: 55px;
    opacity: 1;
    font-size: 16px;
    color: #fefefd;
    background-color: #4a4949;
}

#main_slider .carousel-control-next:focus,
#main_slider .carousel-control-next:hover,
#main_slider .carousel-control-prev:focus,
#main_slider .carousel-control-prev:hover {
    color: #fefefd;
    background-color: #f01c1c;
}


/* gallery section end */


/* ad section start */

#car-detail h2 {
    text-align: center;
    color: #2b3036;
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 3rem;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    /* border: 4px solid #f7d700 !important; */
    border: 4px solid #136270 !important;
}

.ad-header {
    display: flex;
    font-size: 2.5rem;
    font-weight: 800;
    border-bottom: 1px solid #2b3036;
}

.ad-header .ad-title {
    width: 50%;
    color: #2b3036;
}

.ad-header .ad-price {
    width: 50%;
    text-align: right;
    color: #136270;
}

.ad-infos svg {
    color: #f7d700;
}

.ad-infos span {
    color: #4e4e4e
}

.ad-description-item {
    font-size: 1.13rem;
    color: #4e4e4e;
    margin-bottom: 1rem;
}

.ad-description-item-title {
    font-weight: 900;
}

.ad-description-item-value {
    margin-top: -3px;
}

.ad-option-title {
    font-size: 1.13rem;
    color: #4e4e4e;
    font-weight: 900;
}

.ad-options .badge {
    font-size: 1rem;
    margin-right: 0.8rem;
    margin-bottom: 0.5rem;
    --bs-badge-padding-y: 0.55em;
}

.ad-text {
    border: 1px solid #d1d1d1;
    box-shadow: #dbdbdb 0px 1px 7px 0px;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 12px;
    font-size: 1rem;
}

.ad-contact {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

/* ad section end */

/* services section start */
.services-container {
    position: relative;
    margin-bottom: -80px;
}

.services-image{
    border-radius: 1.5rem;
    filter: brightness(0.7);
    min-height: 250px;
    object-fit: cover;
}

.services-imgtext {
    position: absolute;
    top: 15%;
    padding-inline: 15%;
}

.services-title{
    color: white;
    font-size: 3.7rem;
    font-weight: 600;
}

.services-subtitle{
    color: white;
    font-size: 1.7rem;
    font-weight: 300;
}

.services-cards-container {
    padding-inline: 5%;
    position: relative;
    top: -100px
}

.services-card {
    /* border: 2px solid red; */
    /* display: flex; */
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 0.288) 0px 4px 28px 0px;
    border-radius: 15px;
    padding-bottom: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.services-card h1 {
    text-align: center;
    font-size: 1.8rem;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #136270;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.services-card-image {
    width: 35%;
    margin-inline: 30%;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.services-button-container {
    margin-top: 30px;
    padding-inline: 40%;
}

.services-arrow {
    width: 70px;
}

@media only screen and (max-width: 1200px) {
    /* For mobile phones: */
    .services-card h1 {
        font-size: 1.5rem;
    }

    .services-imgtext {
        top: 10%;
    }

    .services-title{
        font-size: 3rem;
        font-weight: 500;
    }

    .services-subtitle{
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 990px) {
    /* For mobile phones: */
    .services-card h1 {
        font-size: 1.15rem;
    }

    .services-imgtext {
        top: 8%;
    }

    .services-title{
        font-size: 2.5rem;
    }

    .services-subtitle{
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 770px) {
    /* For mobile phones: */
    .services-container {
        padding-inline: 0 !important;
        margin-bottom: 0;
    }
    .services-image{
        border-radius: 0;
    }

    .services-cards-container {
        padding-inline: 5%;
        position: relative;
        top: 30px
    }

    .services-imgtext {
        top: 3%;
    }

}


/* services section end */

/* import section start */

.import-title {
    line-height: 1.1;
    font-weight: 800;
    font-size: 45px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.import-image{
    border-radius: 50px;
    filter: brightness(0.4);
}

.image_bandeau_import {
    position: relative;
}
.image_bandeau_import p {
    position: absolute;
    top: 40%;
    font-size: 27px;
    font-weight: 500;
    color: white;
    width: 70%;
    line-height: 1.4;
    margin-left: 8vw;
}

.import-section {
    margin-top: 5vh;
    background-color: #13627031;
    padding-inline: 10vw;
}

.import-section-title {
    text-align: center;
    margin-top: 4vh;
    margin-bottom: 4vh;
    font-size: 30px;
    font-weight: 600;
}

.formule-import {
    border-radius: 15px;
    margin: auto;
    /* width: 50%; */
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.288) 0px 4px 28px 0px;
}

.formule-import h2 {
    font-size: 30px;
    font-weight: 500;
}

.formule-import h3 {
    font-size: 50px;
    font-weight: 700;
    color: #136270;
}

.formule-import ul {
    font-size: 22px;
}

.formule-import li {
    margin-top: 5px;
}

.formule-import ul svg {
    margin-right: 10px;
    font-size: 30px;
}

@media only screen and (max-width: 1200px) {
    /* For mobile phones: */
    .formule-import {
        width: 60%;
        padding: 30px;
    }
}

@media only screen and (max-width: 990px) {
    /* For mobile phones: */
    .formule-import {
        width: 90%;
        padding: 20px;
    }
}


.more-about-import {
    border: 1px solid white;
    background-color: white;
    border-radius: 15px;
    margin-inline: 3vw;
    margin-bottom: 5vw;
    padding-bottom: 2vh;
}

.more-about-import h2 {
    text-align: center;
    margin-top: 2vh;
    font-weight: 500;
}

.more-about-import p {
    font-size: 17px;
    margin-left: 0;
    margin-right: 10px;
}

.button-container {
    margin-top: 40px;
    text-align: center;
}

.green-rounded-button {
    width: 200px;
    height: 45px;
    border-radius: 2em;
    justify-content: center;
    background-color: #136270;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.why-import-row {
    height: 600px;
}

.why-import-img {
    border-radius: 25px;
    height: 600px;
}

.why-import-subsection {
    border-radius: 25px;
    padding: 32px;
    margin-bottom: 20px;
}

.why-import-subsection h2 {
    font-size: 35px;
    font-weight: 600;
}

.why-import-subsection p {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    color: #111111;
}


.how-import-row {
    /* height: 600px; */
    /* border: 1px solid red; */
    margin-bottom: 20px;
}

.how-import-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.how-import-img {
    border-radius: 25px;
    height: 320px;
    width: 320px;
}

.how-import-subsection {
    border-left: 3px solid #136270;
    padding: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.how-import-subsection h1 {
    font-size: 80px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #136270;
}

.how-import-subsection h2 {
    font-size: 35px;
    font-weight: 600;
    color: #136270;
}

.how-import-subsection p {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    color: #2b3036;
}

/* import section end */

/* choose section start */

.choose_section{
    width: 100%;
    float: left;
    background-color: #363636;
    height: auto;
    padding: 40px 0px;
}

.choose_taital{
    width: 100%;
    float: left;
    font-size: 40px;
    color: #fefefd;
    text-transform: uppercase;
    font-weight: bold;
}

.choose_section_2{
    width: 100%;
    float: left;
    padding-top: 40px;
}

.icon_1 {
    width: 100%;
    float: left;
    min-height: 50px;
}

.safety_text{
    width: 100%;
    float: left;
    color: #fefefd;
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
}

.ipsum_text {
    width: 100%;
    float: left;
    color: #fefefd;
    font-size: 16px;
    margin: 0px;
}

/* choose section end */

/* client section start */

.client_section {
    width: 100%;
    float: left;
}

.client_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #292828;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

.client_section_2 {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
    padding-top: 90px;
}

.client_taital_box {
    width: 100%;
    float: left;
    background-color: #ffffff;
    height: auto;
    padding: 0px 20px 30px 20px;
    box-shadow: 0px 0px 10px 10px #ebebea;
}

.client_img {
    width: 100%;
    text-align: center;
    position: relative;
    top: -30px;
}

.moark_text {
    width: 100%;
    float: left;
    font-size: 24px;
    color: #1d1f1e;
    font-weight: bold;
    text-align: center;
    padding: 0px 0px 20px 0px; 
}

.client_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #282828;
    margin: 0px;
    text-align: center;
}

.quick_icon{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 50px;
}


/* client section end */

/* contact section start */

.contact_section {
    width: 100%;
    float: left;
}

.contact_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #363636;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.contact_section_2 {
    width: 100%;
    margin: 0 auto;
    padding: 110px 20px 50px 20px;
    background-color: #fe5b29;
    border-top-left-radius: 540px;
    border-top-right-radius: 540px;
    margin-top: 50px;
}

.padding_left_0 {
    padding-left: 0px;
}

.mail_section_1 {
    width: 60%;
    margin: 0 auto;
}

.mail_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #2a2a2c;
    border: 0px;
    background-color: #ffffff;
    padding: 11px 20px;
    margin-top: 20px;
    border-radius: 5px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

input.mail_text::placeholder {
    color: #2a2a2c;
}

.massage-bt {
    color: #2a2a2c;
    width: 100%;
    height: 110px;
    font-size: 18px;
    background-color: #ffffff;
    padding: 40px 20px 0px 20px;
    border: 0px;
    height: 110px;
    margin-top: 20px;
    border-radius: 5px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

textarea#comment.massage-bt::placeholder {
    color: #2a2a2c;
}

.send_bt {
    width: 170px;
    margin: 0 auto;
    text-align: center;
}

.send_bt a {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #fefefd;
    background-color: #fe2929;
    padding: 12px;
    margin-top: 20px;
    display: block;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
}

.send_bt a:hover {
    color: #363636;
    background-color: #ffffff;
}

.map_main {
    width: 100%;
    float: left;
    margin-top: 90px;
}


/* contact section end */

/* features section start */

#features-section h1 {
    text-align: center;
}

#features-section h4 {
    /* font-size: 25px; */
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
}

.features-row {
    margin-bottom: 30px;
}

.features-col {
    text-align: center;
    padding-top: 30px;
    padding-inline: 30px;
}

/* features section end */

/* Review section start */

.review-card {
    max-width: 400px;
    max-height: 350px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.review-card::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.review-header {
    display: flex;
    margin-bottom: 0;
}

.review-image {
    width: 25%;
    border-radius: 50%;
    margin-right: 16px;
}

.review-title {
    width: 70%;
}


.review-title-date {
    font-size: 15px;
    font-weight: 300;
}

.review-star {
    font-size: 20px;
    color: #f7d700;
}

/* Review section end */

/* Google Maps section start */

.google-map {
    width: 100%;
    height: 50vh;
    border-radius: 1rem;
    overflow: hidden;
}

.map-infos {
    height: 100%;
    padding-left: 1vw;
}

.map-infos h1 {
    color: #136270;
    font-size: 1.8rem;
    font-weight: 900;
}

.map-infos h2 {
    color: inherit;
}

.map-infos-address {
    margin-bottom: 5vh;
}

.map-infos-horaires span{
    display: flex
}

.map-infos-horaires span h2{
    width: 50%;
    display: flex;
    align-items: end;
}

@media only screen and (max-width: 990px) {
    /* For mobile phones: */
    .map-infos {
        margin-top: 2rem;
    }
}


/* Google Maps section end */



/* CGV & Mentions legales section start */

#cgv-section li, #mentions-legales-section li {
    padding-left: 2vw;
    list-style: inside;
}

#cgv-section p, #mentions-legales-section p {
    font-size: 17px;
}

#cgv-section h1, #mentions-legales-section h1 {
    margin-top: 50px;
}

#cgv-section h2, #mentions-legales-section h2 {
    padding: 0;
}

#cgv-section a, #mentions-legales-section a {
    color:#136270;
    font-weight: 400;
}

/* CGV & Mentions legales section end */


/* footer section start */

.footer_section {
    width: 100%;
    float: left;
    height: auto;
    background-color: #2b3036;
    padding-top: 40px;
}

.footeer_logo{
    width: 100%;
    float: left;
    text-align: center;
}

.footer_section_2{
    width: 100%;
    float: left;
    padding-top: 50px;
}

.footer_taital {
    width: 100%;
    float: left;
    font-size: 26px;
    color: #fefefd;
    font-weight: bold;
    padding-bottom: 20px;
}

.lorem_text {
    width: 60%;
    float: left;
    font-size: 16px;
    color: #fefefd;
    margin: 0px;
}

.footer_section li{
    width: 100%;
    float: left;
    font-size: 16px;
    color: #fefefd;
    margin: 0px;
}

.social_icon {
    width: 100%;
    float: left;
    margin-top: 10px;
}

.social_icon ul {
    margin: 0px;
    padding: 0px;
}

.social_icon li {
    float: left;
}

.social_icon li a {
    float: left;
    padding: 2px 7px;
    background-color: #ffffff;
    color: #2e2e2e;
    border-radius: 100%;
    font-size: 20px;
    margin-right: 5px;
    width: 41px;
    text-align: center;
    margin-top: 10px;
}

.social_icon li a:hover {
    background-color: #fe5b29;
    color: #ffffff;
}

.update_mail {
    color: #ffffff;
    width: 100%;
    height: 55px;
    font-size: 18px;
    padding: 10px 20px 0px 0px;
    background-color: transparent;
    border-bottom: 1px solid #ffffff !important;
    border: 0px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: block;
}

textarea#comment::placeholder {
    color: #fff;
}

.subscribe_bt {
    width: 140px;
    float: right;
    margin-top: 20px;
}

.subscribe_bt a {
    width: 100%;
    float: left;
    color: #ffffff;
    font-size: 16px;
    padding: 8px 0px;
    text-transform: uppercase;
    background-color: #fe5b29;
    text-align: center;
    border-radius: 3px;
}

.subscribe_bt a:hover {
    color: #363636;
    background-color: #ffffff;
}

.location_text {
    width: 100%;
    float: left;
}

.location_text a {
    width: 100%;
    float: left;
    color: #ffffff;
    font-size: 16px;
}

.location_text a:hover {
    color: #fe5b29;
}

.padding_left_15 {
    padding-left: 10px;
}



/* footer section end */


/* copyright section start */

.copyright_section {
    width: 100%;
    float: left;
    background-color: #363636;
    height: auto;
}

.copyright_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #fefefd;
    text-align: center;
    margin-left: 0px;
    font-family: 'Poppins', sans-serif;
}

.copyright_text a {
    color: #fefefd;
}

.copyright_text a:hover {
    color: #fe5b29;
}


/* copyright section end */

.margin_top90 {
    margin-top: 90px;
}


/* ================================= */
/* =        ADMIN SECTION            */
/* ================================= */

.sidebar-container h1 {
    padding: 0;
    font-weight: 400;
    font-size: 1.08rem;
    line-height: 1.5;
    color: #ffffff;
}

.sidebar-container h6 {
    color: #adadad;
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-top: 1.5rem;
}

.sidebar-container svg {
    color: #f7d700;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-collapse-button {
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.28571rem;
    padding: 8px;
    border-radius: 50%;
    overflow: visible;
    color: rgb(255, 255, 255);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);

}

.sidebar-header h3 {
    color: #ffffff;
    padding: 0px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.167;
}

.sidebar-profile {
    margin-bottom: 25px;
}

.sidebar-name {
    text-align: center;
}

.sidebar-name h2 {
    font-size: 32px;
    line-height: 1.2;
    color: #ffffff;
    font-weight: 700;
    margin: 10px 0px 0px;
}

.sidebar-container a::before {
    background-color: initial;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.user-profile {
    display: flex; 
    margin: auto;
    cursor: pointer; 
    border : 3px solid white; 
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.288) 0px 4px 28px 0px;
}

.sidebar-logout {
    margin-top: 100px;
    margin-bottom: 1rem;
}

.app-version {
    display: flex;
    justify-content: center;
    color: white;
}

.disconnect-button {
    display: flex;
    margin: auto;
}

#admin {
    display: flex;
    height: 100%;
    width: 100%;
}

.admin-container {
    width: 100%;
    height: 100%;
}

.admin-container h1 {
    color: #136270;
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 700;
}

.admin-form {
    background-color: #136270;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 1rem;
}

.admin-form h2 {
    color: white;
}

.admin-form-separator {
    border: 1px solid #f7d700;
    margin-bottom: 1rem;
}

.options-accordion h2 {
    padding: 0;
}

.options-accordion button {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-inline: 0.75rem;
}

.upload__image-wrapper {
    display: flex;
    margin-bottom: 1rem;
}

.upload__image-wrapper .image-item {
    margin-inline: 10px;
}

.upload__image-wrapper img {
    border-radius: 10px;
}

.image-item__btn-wrapper button {
    margin-right: 5px;
}

.upload-button {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    background-color: #ffffffe8;
}

.upload-button svg {
    width: 30px;
    height: 30px;
}

.delete-button svg {
    width: 25px;
    height: 25px;
    margin-inline: 20px;
}

.publish-container {
    display: grid;
    justify-content: center;
}

.publish-container label {
    color: white;
    font-size: 0.9rem;
    margin-right: 20px;
}

.file-uploader-container h6 {
    text-align: center;
    color: white;
    margin-top: 7px;
    font-size: 1rem;
}

.file-uploader {
    background-color: #ffffffe8;
    height: 150px;
    border-radius: 1rem;
    align-content: center;
}

.file-uploader svg {
    display: flex;
    margin: auto;
    width: 35%;
    height: 35%;
    color: #136270;
}

#manage-ads .dropdown-toggle::after {
    display: none !important; 
}
  

.customer-info {
    background-color: #136270;
    box-shadow: #dbdbdb 0px 1px 7px 0px;
    border-radius: 1rem;
    padding: 1rem;
    color: white;
}

.customer-info h2 {
    color: #f7d700;
    font-size: 1.6rem;
    text-align: center;
}

.customer-info h3 {
    text-align: center;
    color: #ffffffe8;
}

.customer-info .line-separator {
    border-top: 1px solid #ffffffb9;
    margin-bottom: 1.5rem;
}

.customer-info svg {
    margin-right: 0.9rem;
}

.customer-info li {
    font-size: 1.1rem;
}

.customer-documents, .customer-ads {
    border: 1px solid #d1d1d1;
    box-shadow: #dbdbdb 0px 1px 7px 0px;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 12px;
}